import './main.css';
import '../public/css/rte.css';
import '../public/css/modal.css';

import { Elm } from './App.elm';
import * as serviceWorker from './serviceWorker';
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '../js/elmEditor.js';

import 'intro.js/introjs.css'
import introJs from 'intro.js';

const app = Elm.App.init({
  node: document.getElementById('root')
  , flags: { endpoint: process.env.ELM_APP_PROD_URL }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

app.ports.startTour.subscribe(function(msg) {
  introJs().onafterchange(function(e) {
    let a = document.querySelector('.introjs-helperLayer');
    switch (e.title) {
      case 'text':
        a.style.top = parseFloat(a.style.top) - 2 + "px";
        a.style.left = parseFloat(a.style.left) + 1 + "px";
        break;
      case 'undo':
        a.style.top = parseFloat(a.style.top) - 1 + "px";
        a.style.left = parseFloat(a.style.left) - 8 + "px";
      default:
        break;
    } 
  }).start();
});

// app.ports.pageSend.subscribe( function(page) {
//   if (page == 2) {
//     setTimeout(function() {
//       let e = document.querySelector('.rte-main');
//       e.addEventListener('scroll', scrollF);
//     }, 500);
//   }


//     function scrollF() {
//       let e = document.querySelector('.rte-main');
//       let h = e.offsetHeight;
//       var newpage = parseInt(e.scrollTop / h) + 2;
//         // app.ports.receivePage.send(newpage)
//     }
// });
